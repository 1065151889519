// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { hideVisually } from 'polished';

// utils
import { greaterThan } from '@utils/mediaQuery';

// components
import Button from '@components/Button';
import Layout from '@components/Layout';

// images
import vectorEcological from '@images/vectorEcological.svg';
import vectorSale from '@images/vectorSale.svg';
import vectorInstallation from '@images/vectorInstallation.svg';
import IconArrow from '@images/IconArrow';
import StampCanada from '@images/StampCanada';

// views
import HeroSection from '@views/IndexPageView/HeroSection';
import ProfilingSection from '@views/IndexPageView/ProfilingSection';
import MissionSection from '@views/IndexPageView/MissionSection';
import ReasonsSection from '@views/IndexPageView/ReasonsSection';
import ProjectsSection from '@views/IndexPageView/ProjectsSection';

// styles
import {
  Content,
  List,
  ListItem,
  ImageWrapper,
  Image,
  Description,
  Title,
} from '@views/IndexPageView/ReasonsSection/ReasonsSection.styles';
import SEO from '@src/components/SEO';
import { magnifyStyle } from '@src/styles/global';

const HomePage = ({ data }) => {
  const {
    projects: { nodes: projects },
  } = data;

  const langLinks = [{ langKey: 'fr', href: '/' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang='en'
        langLinks={langLinks}
        description='Discover Permafil’s wide range of products. We’re your go-to choice for practical storage solutions for your home or your commercial and residential projects.'
      />

      <HeroSection renderStamp={<StampCanada />}>
        <p>
          Consume local!<span css={hideVisually()}> - </span>
          <span>
            Buy
            <br />
            our Permafil products!
          </span>
        </p>
      </HeroSection>

      <ProfilingSection />

      <MissionSection>
        <h2 css={hideVisually}>Our mission</h2>

        <p css={magnifyStyle}>
          Founded over 30 years ago and well-established in Québec, Permafil has
          made commercial and domestic storage spaces our specialty.
        </p>

        <p>
          We take pride in designing and marketing the best solutions on the
          market. Our portfolio includes wire and melamine shelving, wire mesh
          lockers and partitions, bicycle racks, window coverings, walk-in
          closets, displays and a variety of different accessories.
        </p>

        <p>
          The name Permafil is synonymous with guaranteed quality and
          reliability for all our customers. In addition, our professional
          installers perform meticulous and efficient work. You will have total
          peace of mind!
        </p>

        <p>
          Are you looking for a sub-contractor for finishing projects? At
          Permafil, our team meets CCQ standards and are skilled in carrying out
          this type of work across Québec.
        </p>
      </MissionSection>

      <ReasonsSection>
        <Title>Choosing Permafil will:</Title>

        <Content>
          <List>
            <ListItem>
              <ImageWrapper>
                <Image src={vectorEcological} />
              </ImageWrapper>

              <Description>
                Reduce your carbon footprint by installing local products,
                without compromising quality
              </Description>
            </ListItem>

            <ListItem>
              <ImageWrapper>
                <Image src={vectorSale} />
              </ImageWrapper>

              <Description>
                Ensure you get the most professional service before, during and
                after the sale
              </Description>
            </ListItem>

            <ListItem>
              <ImageWrapper>
                <Image src={vectorInstallation} />
              </ImageWrapper>

              <Description>
                Give you the advantage of an installation team that makes your
                goals their very own
              </Description>
            </ListItem>
          </List>
        </Content>

        <Button
          outlined
          primary
          renderIcon={<IconArrow />}
          to='/en/contact-us'
          tag='link'
          css={`
            ${greaterThan(480)} {
              align-self: center;

              min-width: 408px;
            }
          `}
        >
          Contact us
        </Button>
      </ReasonsSection>

      <ProjectsSection projects={projects} />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default HomePage;

export const query = graphql`
  query homePageQuery {
    projects: allSanityProject(
      filter: { localization: { locale: { eq: "en" } } }
      sort: { fields: sortOrder, order: ASC }
      limit: 3
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        title
        city
        state
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 416, maxHeight: 318) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        localization {
          locale
        }
      }
    }
  }
`;
