import React from 'react';

const StampCanada = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 223.475 224.737'
    {...props}
  >
    <g transform='translate(-314.1 -81.988)'>
      <circle
        cx='85.747'
        cy='85.747'
        r='85.747'
        fill='none'
        stroke='#f33'
        strokeWidth='2'
        strokeMiterlimit='10'
        transform='translate(340.251 107.98)'
      />
      <g transform='translate(314.1 81.988)'>
        <path
          d='M327.25 102.613l-.313-.4-2.068 1.49.347.442a21.424 21.424 0 0 1 3.272 5.694c1.313 3.428 2.041 7.836-1.945 9.367s-6.408-2.224-7.721-5.646a21.734 21.734 0 0 1-1.374-6.435l-.041-.565-2.537.286.027.51a22.1 22.1 0 0 0 1.531 7.115c1.429 3.734 3.347 6.156 5.707 7.2a6.445 6.445 0 0 0 2.646.565 7.623 7.623 0 0 0 2.714-.524 6.9 6.9 0 0 0 3.966-3.612c1.054-2.36.864-5.449-.571-9.183a21.87 21.87 0 0 0-3.64-6.304z'
          fill='#f33'
          transform='translate(-309.63 35.357)'
        />
        <path
          d='M330.865 119.964l-.483-3.068-3.707-1.619-1.653-10.442 3.02-2.707-.49-3.061-13.224 11.584.381 2.381 15.251 6.537zm-13.435-8.585l5.149-4.442.15-.15 1.156 7.319z'
          fill='#f33'
          transform='translate(-312.769 17.109)'
        />
        <path
          d='M329.006 114.582l.146-2.664-10.569-.591-.571-.02L329.7 99.49l.146-.146.126-2.332-14.362-.8-.545-.027-.146 2.664 10.443.578.718.033-11.708 11.84-.146.146-.126 2.305 14.362.8z'
          fill='#f33'
          transform='translate(-314.1 .367)'
        />
        <path
          d='M327.441 110.657l-2.728-3.013 2.769-10.258 3.884-1.238.8-2.993-16.575 5.095-.286.088-.632 2.34 11.965 12.979zm-3.054-12.339l.2-.075-1.946 7.19-4.8-5.156z'
          fill='#f33'
          transform='translate(-310.773 -17.195)'
        />
        <path
          d='M331.964 103.8c4.258-8.285.742-11.652-1.728-12.931a6.356 6.356 0 0 0-6.013-.15c-2.02 1-3.87 3.115-5.5 6.285l-3.333 6.483 13.224 6.8zm-13.081-1.5l2.136-4.129c2.415-4.721 5.129-6.381 7.986-4.891s3.1 4.632.673 9.353l-2.109 4.115z'
          fill='#f33'
          transform='translate(-306.589 -35.325)'
        />
        <path
          d='M0 0H2.668V14.91H0z'
          fill='#f33'
          transform='rotate(-54.816 56.654 5.029)'
        />
        <path
          d='M325.045 103.594l-1-3.932 7.2-7.748 3.714.66.292.048 2.1-2.265-16.986-3.2-.292-.061-1.653 1.769 4.524 17.006zm3.041-12.305l.2.034-5.04 5.435-1.837-6.768z'
          fill='#f33'
          transform='translate(-289.031 -52.375)'
        />
        <path
          d='M328.588 106.461l2.192-1.515-6.032-8.646-.339-.465 16.461 1.907.2.027 1.913-1.335-8.516-12.2-2.186 1.528 5.959 8.538.412.571-16.474-1.907-.2-.027-1.893 1.322 8.2 11.758z'
          fill='#f33'
          transform='translate(-279.37 -68.995)'
        />
        <path
          d='M331.245 101.693l-2.87-9.825-.153-.5 9.141 5.992 1.442-.418 4.491-9.984L346.3 97.3l2.571-.751-4.187-14.349-2.285.671-5.129 11.455-10.3-6.756-.213-.133-2.265.658 4.192 14.335z'
          fill='#f33'
          transform='translate(-253.815 -80.742)'
        />
        <path
          d='M339.2 82l-2.422.136-8.564 15.428 3.095-.17 2.007-3.544 10.6-.592 2.408 3.3 3.1-.17-10.051-14.146zm-4.428 9.2l3.381-6.177 4.047 5.762z'
          fill='#f33'
          transform='translate(-232.185 -81.938)'
        />
        <path
          d='M346.527 99.087l2.3.372 2.338-14.721-2.631-.419-1.741 11.007-10.563-12.82-.133-.159-2.272-.359-2.338 14.712 2.63.419 1.654-10.429.08-.565 10.549 12.8z'
          fill='#f33'
          transform='translate(-213.21 -81.988)'
        />
        <path
          d='M341.169 99.955a14.623 14.623 0 0 0 5.047 1.109 5.894 5.894 0 0 0 2.5-.49 5.765 5.765 0 0 0 2.966-3.435l3.143-8.244-2.5-.959-3.143 8.258a3.245 3.245 0 0 1-1.667 1.966c-1.17.537-2.952.333-5.435-.612s-3.939-1.973-4.476-3.149a3.26 3.26 0 0 1 .054-2.585l3.149-8.258-2.5-.939-3.136 8.238c-2.022 5.332 2.46 7.745 5.998 9.1z'
          fill='#f33'
          transform='translate(-194.745 -78.346)'
        />
        <path
          d='M339.474 97.948l3.414-5.381L353.2 99.11l1.362-2.166L344.27 90.4l1.814-2.863 11.3 7.181 1.375-2.166-13.552-8.6-7.978 12.562z'
          fill='#f33'
          transform='translate(-179.897 -70.599)'
        />
        <path
          d='M341.327 92.87l3.932-1.007 7.748 7.217-.653 3.7-.047.292 2.265 2.1 3.2-16.992.061-.292-1.769-1.646-17 4.524zm12.305 3.041l-.034.211-5.435-5.054 6.768-1.823z'
          fill='#f33'
          transform='translate(-169.218 -57.289)'
        />
        <path
          d='M349.865 108l.415.3 1.612-1.959-.442-.34a21.27 21.27 0 0 1-4.626-4.68c-1.639-2.272-2.456-4.285-2.435-5.986a4.72 4.72 0 0 1 5.272-4.449c1.83.3 3.707 1.775 5.578 4.381a21.581 21.581 0 0 1 2.959 5.864l.184.537 2.381-.9-.156-.5a22.209 22.209 0 0 0-3.279-6.51c-2.075-2.877-4.265-4.666-6.517-5.326a6.892 6.892 0 0 0-6.054 1.143c-3.993 2.877-4.007 7.707-.027 13.251a22.329 22.329 0 0 0 5.135 5.174z'
          fill='#f33'
          transform='translate(-153.642 -46.15)'
        />
        <path
          d='M343.906 102.964l-.492.226 1.076 2.431 11.273-5.062 2.949 6.576 2.345-1.036-6.769-15.106-.226-.492-2.345 1.056 2.949 6.576z'
          fill='#f33'
          transform='translate(-144.001 -32.589)'
        />
        <path
          d='M345.211 97.881c-1.068 1.694-1.218 4.17-.442 7.571 1.129 4.966 3.245 7.4 6.462 7.4a8.069 8.069 0 0 0 1.7-.2l8.6-1.952-.585-2.605-8.612 1.959c-2.632.592-4.109-.98-5.061-5.177-.592-2.585-.537-4.367.15-5.462a3.286 3.286 0 0 1 2.184-1.388l8.612-1.959-.585-2.592-8.6 1.959a5.752 5.752 0 0 0-3.823 2.446z'
          fill='#f33'
          transform='translate(-138.975 -15.309)'
        />
        <path
          d='M344.734 97.134l.041 2.667 4.932-.1.1 5.075-4.714 6.932-.1.136.068 4.4 4.87-7.306a6.121 6.121 0 0 0 1.578 4.143 4.609 4.609 0 0 0 3.435 1.218c3.2-.068 4.932-2.184 4.877-5.945l-.211-11.489-14.332.252zm7.693 11.333l-.156-8.8 4.823-.082.15 8.857a3.485 3.485 0 0 1-.708 2.551 2.213 2.213 0 0 1-1.633.605c-.632.002-2.421.053-2.476-3.131z'
          fill='#f33'
          transform='translate(-136.348 4.355)'
        />
        <path
          d='M344.287 116.029l2.524.478 2.538-13.572 3.74.7-2.139 11.479-.093.531 2.524.478 2.232-12.024 3.335.624-2.458 13.177 2.531.478 2.943-15.8-14.664-2.744z'
          fill='#f33'
          transform='translate(-138.941 21.559)'
        />
        <path
          d='M349.305 105.025l4.578 1.85-1.9 4.721-7.2 4.619-1.646 4.081 7.353-4.83a6.076 6.076 0 0 0-.183 4.456 4.624 4.624 0 0 0 2.687 2.462 5.583 5.583 0 0 0 2.1.442 3.972 3.972 0 0 0 1.6-.32 6.257 6.257 0 0 0 3.129-3.694l4.32-10.666-13.816-5.592zm11.442 4.632l-3.326 8.224c-1.184 2.9-2.871 2.231-3.415 2s-2.245-.918-1.041-3.871l3.3-8.163z'
          fill='#f33'
          transform='translate(-145.661 37.353)'
        />
        <path
          d='M320.2 106.947a2.141 2.141 0 0 0-1.673.429 2.313 2.313 0 0 0-1.007 1.49 2.806 2.806 0 0 0 .6 2.088 2.571 2.571 0 0 0 2.1 1.272 2.529 2.529 0 0 0 1.408-.469c.918-.66 1.49-1.925.354-3.53a2.837 2.837 0 0 0-1.782-1.28z'
          fill='#f33'
          transform='translate(-294.447 62.634)'
        />
        <path
          d='M330.474 117.126c-2.456-2.394-4.252-4.32-3.252-5.449a1.248 1.248 0 0 1 .891-.422l.1-.007c.993 0 2.483.816 4.1 2.252a32.323 32.323 0 0 1 4.823 5.435l.313.449 2.109-1.49-.306-.449a35.045 35.045 0 0 0-5.054-5.694c-4.9-4.367-7.619-3.394-9.027-1.8-2.653 2.979.734 6.333 3.456 9.027l.15.143c2.483 2.469 3.843 3.9 3.007 4.843a1.21 1.21 0 0 1-.884.408h-.116c-1.327 0-3.272-1.306-4.666-2.551a24.533 24.533 0 0 1-5-6.238l-.279-.476-2.177 1.306.245.456a25.086 25.086 0 0 0 5.306 6.687c1.462 1.306 4.251 3.442 6.762 3.442.082 0 .163 0 .252-.007a3.7 3.7 0 0 0 2.612-1.279c2.532-2.838-.739-6.028-3.365-8.586z'
          fill='#f33'
          transform='translate(-287.65 72.454)'
        />
        <path
          d='M331.641 111.64l-2.205-1.5-8.443 12.323 2.2 1.515 8.144-11.884z'
          fill='#f33'
          transform='translate(-274.106 81.35)'
        />
        <path
          d='M341.84 117.4l-5.062 9.891-6.032-15.432-.073-.193-2.053-1.043-6.776 13.219 2.358 1.216 4.823-9.373.239-.5 6.025 15.412.073.193 2.073 1.056 6.782-13.233z'
          fill='#f33'
          transform='translate(-269.16 84.161)'
        />
        <path
          d='M337.737 112.906c-3.408-.959-6.217-.986-8.394-.1a6.764 6.764 0 0 0-4.013 4.639 6.871 6.871 0 0 0 .524 5.34c1.252 2.252 3.837 3.939 7.673 5.02a23.108 23.108 0 0 0 6.217.9c.442 0 .782-.02 1.007-.027l.51-.02.041-2.544-.558.007a20.957 20.957 0 0 1-6.524-.782c-3.524-1-7.476-3.054-6.319-7.163 1.177-4.115 5.6-3.789 9.135-2.782A21.448 21.448 0 0 1 343 118.13l.469.306 1.292-2.184-.422-.293a21.992 21.992 0 0 0-6.602-3.053z'
          fill='#f33'
          transform='translate(-250.719 93.095)'
        />
        <path
          d='M328 127.521l16.448 1.388.219-2.564-13.791-1.156.325-3.813 12.21 1.036.206-2.564-12.19-1.036.286-3.381 13.387 1.129.206-2.571-16.049-1.355z'
          fill='#f33'
          transform='translate(-233.439 95.828)'
        />
        <path
          d='M341.972 124.626l-2.1-11.572-.1-.531-2.046.365-5.507 2.9 1.2 2.1 4.238-2.106 1.694 9.327-5.141.923.471 2.531 12.881-2.345-.462-2.518z'
          fill='#f33'
          transform='translate(-208.954 95.182)'
        />
        <path
          d='M340.512 112.456c-4.612 1.585-6.836 4.8-5.673 8.17 1.238 3.592 5.245 4.047 8.455 2.925a9.08 9.08 0 0 0 4.9-3.755 4.355 4.355 0 0 1-.381 2.224 6.1 6.1 0 0 1-3.755 2.857 23.69 23.69 0 0 1-5.319 1.041l-.551.048.272 2.565.524-.034a22.857 22.857 0 0 0 5.762-1.15c2.694-.925 4.517-2.36 5.442-4.251a8.018 8.018 0 0 0 .047-6.279c-1.553-4.517-4.9-6.034-9.723-4.361zm2.374 8.693a5.734 5.734 0 0 1-4.183.088 2.541 2.541 0 0 1-1.333-1.517 2.648 2.648 0 0 1 .116-2.075 6.338 6.338 0 0 1 3.768-2.762 7.813 7.813 0 0 1 2.551-.476 3.177 3.177 0 0 1 3.258 1.966 2.658 2.658 0 0 1-.939 2.837 9.012 9.012 0 0 1-3.238 1.94z'
          fill='#f33'
          transform='translate(-195.531 90.959)'
        />
        <path
          d='M349.791 116.568c1.163-1.442 1.456-2.9.748-4.129v-.007c-1.367-2.394-4.632-2.435-8.755-.1-4.1 2.34-5.734 5.177-4.367 7.585.7 1.231 2.116 1.721 3.932 1.456-1.428 1.558-1.728 3.081-.9 4.544a3.508 3.508 0 0 0 3.272 1.741 12.312 12.312 0 0 0 5.728-1.891c4.353-2.49 6.068-5.285 4.707-7.666-.828-1.472-2.311-1.955-4.365-1.533zm-6.728-2.007a8.844 8.844 0 0 1 3.741-1.292 1.663 1.663 0 0 1 1.558.789c.83 1.469-2.034 3.306-3.3 4.027-1.945 1.109-3.51 1.585-4.415 1.333a1.1 1.1 0 0 1-.714-.551 1.5 1.5 0 0 1-.156-1.156c.266-.966 1.463-2.111 3.286-3.149zm8.687 5.619c-.272.993-1.544 2.184-3.585 3.347s-3.7 1.646-4.707 1.381a1.41 1.41 0 0 1-.857-.673c-.19-.333-.367-.891.347-1.789a11.069 11.069 0 0 1 3.2-2.462 9.277 9.277 0 0 1 4.177-1.531 2.227 2.227 0 0 1 .531.061 1.212 1.212 0 0 1 .755.585v.007a1.38 1.38 0 0 1 .139 1.074z'
          fill='#f33'
          transform='translate(-181.552 84.079)'
        />
        <path
          d='M351.22 113.173a7.994 7.994 0 0 0-5.578 2.041 9.083 9.083 0 0 0-3.285 5.251 4.281 4.281 0 0 1-.422-2.238 6.045 6.045 0 0 1 2.5-3.993 23.845 23.845 0 0 1 4.619-2.85l.5-.238-1.15-2.279-.476.2a22.644 22.644 0 0 0-5.02 3.109c-4.279 3.551-4.782 7.741-1.421 11.788 1.626 1.959 3.435 2.945 5.353 2.945a8.5 8.5 0 0 0 5.3-2.272c3.755-3.116 4.721-6.911 2.442-9.653a4.888 4.888 0 0 0-3.362-1.811zm-.755 2.6a2.565 2.565 0 0 1 2.047.952c1.293 1.558.551 3.809-1.932 5.877-2.837 2.347-4.979 1.823-5.945.667-1.476-1.775.517-4.524 2.217-5.932a6.182 6.182 0 0 1 3.613-1.566z'
          fill='#f33'
          transform='translate(-167.915 73.972)'
        />
        <path
          d='M343.194 108.621a2.815 2.815 0 0 0-.694 2.082 2.225 2.225 0 0 0 .912 1.476 2.439 2.439 0 0 0 1.456.524 2.649 2.649 0 0 0 2.088-1.211 2.353 2.353 0 0 0-.279-3.564c-.898-.674-2.272-.872-3.483.693z'
          fill='#f33'
          transform='translate(-149.413 65.599)'
        />
      </g>
      <path
        d='M443.093 140.6l-23.839-.927-6.4-11.035-14.1 13.859 9.555-34.666-14.489-3.369-11.456-15.5-11.456 15.5-14.489 3.369 9.555 34.666-14.1-13.859-6.4 11.035-23.84.927 7.919 21.06-7.16 11.541 23.334 11.119v13.141l36.643-4.212 36.63 4.212V184.32l23.335-11.12-7.16-11.541z'
        fill='none'
        stroke='#f33'
        strokeWidth='2'
        strokeMiterlimit='10'
        transform='translate(43.641 40.466)'
      />
      <path
        d='M0 0v133.257'
        transform='translate(425.998 129.429)'
        fill='none'
        stroke='#f33'
        strokeWidth='2'
      />
      <path
        d='M321.621 96.554l60.064 36.686 61.408-36.686'
        fill='none'
        stroke='#f33'
        strokeWidth='2'
        strokeMiterlimit='10'
        transform='translate(43.641 84.513)'
      />
    </g>
  </svg>
);

export default StampCanada;
